/* eslint-disable @next/next/no-img-element */
import type { ReactElement } from 'react'
import { ProductLabelV2Fragment } from '@/api'

export default function ImageLabel({
  src,
  content,
  label,
}: ProductLabelV2Fragment): ReactElement {
  return (
    <img
      key={label}
      className="h-full w-auto"
      src={src ?? ''}
      alt={content ?? ''}
      width={400}
      height={400}
    />
  )
}
