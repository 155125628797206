'use client'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ProductStockStatus, useProductModalDataQuery } from '@/api'
import { ProductDetailStockSubscribe } from '@/modules/product/components/detail/components/stock-subscribe'
import { PurchaseDialogConfigurableVariants } from './purchase-dialog-configurable-variants'
import { PurchaseDialogGiftCardVariants } from './purchase-dialog-gift-card-variants'
import {
  ConfigurableProductModalData,
  GiftCardProductModalData,
  ProductListItem,
  ProductType,
} from '@/common/types/product-types'
import { getQueryClientServer } from '@/common/services'
import { handleServerErrors } from '@/common/utils/error-handling-utils'
import { PurchaseDialogConfigurableVariantsSkeleton } from './purchase-dialog-configurable-variants-skeleton'
import { ProductDataContextProvider } from '@/providers/product-data/product-data-context'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { ListItemProductType, isOfType, obsoloteGetPrice } from '@/common/utils'
import { getInitialConfigurableProductVariant } from '@/providers/product-data/utils'
import { Modal } from '../modal/modal'
import { getPathnameWithoutLocale } from '@/common/utils/url-utils'
import { AddToCartEventHandlerParams } from '@/providers/cart/cart.types'

export type PurchaseDialogProduct = ProductListItem & {
  productType?: ListItemProductType
  parentSku: string
}

export type PurchaseDialogProps = {
  productListData?: PurchaseDialogProduct
  isOpen: boolean
  recommendedSimpleSkus?: string[]
  onClose?: () => void
  onAddToCartEvent?: (params: AddToCartEventHandlerParams) => Promise<void>
}

export function PurchaseDialog({
  productListData,
  isOpen,
  onClose,
  recommendedSimpleSkus,
  onAddToCartEvent,
}: PurchaseDialogProps): JSX.Element | null {
  const isLongTermUnavailable = productListData?.longTermUnavailable !== 0
  const [initialized, setInitialized] = useState<boolean>(false)
  const gtmLock = useRef<boolean>(false)

  const inStock = useMemo(
    () =>
      !isLongTermUnavailable ||
      productListData?.productType === ListItemProductType.GiftCard,
    [isLongTermUnavailable, productListData],
  )

  const [productData, setProductData] = useState<
    ConfigurableProductModalData | GiftCardProductModalData
  >()

  const fetchProductData = useCallback(
    async (
      pathname: string,
    ): Promise<ConfigurableProductModalData | GiftCardProductModalData> => {
      const queryClient = getQueryClientServer()

      const productDataQuery = await queryClient.fetchQuery({
        queryKey: useProductModalDataQuery.getKey({
          url: getPathnameWithoutLocale(pathname),
        }),
        queryFn: useProductModalDataQuery.fetcher({
          url: getPathnameWithoutLocale(pathname),
        }),
      })

      const productData =
        (productDataQuery?.route as
          | ConfigurableProductModalData
          | GiftCardProductModalData) || {}

      handleServerErrors(productData)
      setInitialized(true)

      return productData
    },
    [],
  )

  useEffect(() => {
    const fetchData = async () => {
      setProductData(
        await fetchProductData(
          productListData?.canonicalUrl?.split('?')[0] || '',
        ),
      )
    }

    fetchData()
  }, [productListData?.canonicalUrl, fetchProductData])

  useEffect(() => {
    if (productData && initialized && gtmLock.current === false) {
      const price = obsoloteGetPrice({
        price_range: productData.price_range,
        msrp_price: productData.msrp_price,
      })
      const productTrackingData = {
        id: Number(productData.id),
        name: productData.name ?? '',
        brand:
          productData.__typename === ProductType.Configurable &&
          productData.manufacturer_info
            ? productData.manufacturer_info?.name ?? ''
            : 'GymBeam',
        category: productData.breadcrumb_en ?? '',
        price: price.finalPrice?.value_excl_tax ?? NaN,
      }

      const configurableProductVariant =
        productData.__typename === ProductType.Configurable
          ? getInitialConfigurableProductVariant(
              productData.configurable_variants,
            )
          : undefined

      gtmTracker.trackProductVariantsDetail({
        productData: productTrackingData,
        price: price.finalPrice?.value_excl_tax ?? NaN,
        currency: price.finalPrice?.currency ?? '',
        variantId: configurableProductVariant?.product?.id ?? undefined,
      })
      gtmLock.current = true
    }
  }, [productData, initialized])

  const preselectedVariantSku = useMemo(() => {
    if (
      isOfType<PurchaseDialogProduct>(productListData, 'configurableVariants')
    ) {
      const variants = productListData?.configurableVariants ?? []

      return (
        variants
          .map((variant) => variant?.product?.sku)
          .find((sku) => recommendedSimpleSkus?.includes(sku ?? '')) ??
        undefined
      )
    }

    return undefined
  }, [productListData, recommendedSimpleSkus])

  const preselectedProductImage = useMemo(
    () =>
      isOfType<ConfigurableProductModalData>(
        productData,
        'configurable_variants',
      )
        ? productData.configurable_variants.find(
            (variant) => variant?.product?.sku === preselectedVariantSku,
          )?.product?.image?.url?.full ?? productData?.image.url?.full
        : productData?.image.url?.full,
    [productData, preselectedVariantSku],
  )

  return (
    <Modal isOpen={isOpen} onClose={() => onClose?.()} className="w-[500px]">
      {productListData && productData ? (
        inStock ? (
          <>
            {productListData.productType ===
              ListItemProductType.Configurable && (
              <ProductDataContextProvider
                productId={productData.id}
                productSku={productData.sku}
                outOfStock={
                  productData.stock_status === ProductStockStatus.OutOfStock
                }
                configurableVariants={
                  (productData as ConfigurableProductModalData)
                    .configurable_variants
                }
                giftCardAmounts={[]}
              >
                <PurchaseDialogConfigurableVariants
                  productData={
                    {
                      ...productData,
                      image: {
                        ...productData.image,
                        url: {
                          ...productData.image.url,
                          full: preselectedProductImage,
                        },
                      },
                    } as ConfigurableProductModalData
                  }
                  isInStock={inStock}
                  isLongTermUnavailable={isLongTermUnavailable}
                  onClose={onClose}
                  preselectedVariantSku={preselectedVariantSku}
                  onAddToCartEvent={onAddToCartEvent}
                />
              </ProductDataContextProvider>
            )}
            {productListData.productType === ListItemProductType.GiftCard && (
              <PurchaseDialogGiftCardVariants
                productData={productData as GiftCardProductModalData}
                onClose={onClose}
              />
            )}
          </>
        ) : (
          <ProductDetailStockSubscribe />
        )
      ) : (
        <PurchaseDialogConfigurableVariantsSkeleton />
      )}
    </Modal>
  )
}
