'use client'

import Image from 'next/image'

import { ProductName } from '@/components/product-name'
import { GiftVariants } from '@/components/variants'
import { GiftCardProductAmountFragment, ProductStockStatus } from '@/api'
import { GiftCardProductModalData } from '@/common/types/product-types'
import { ProductDataContextProvider } from '@/providers/product-data/product-data-context'

export type Props = {
  productData: GiftCardProductModalData
  onClose?: () => void
}

export function PurchaseDialogGiftCardVariants({
  productData,
  onClose,
}: Props): JSX.Element {
  return (
    <div className="mb-4 text-left">
      <Image
        src={productData?.image?.url?.full ?? ''}
        alt={productData?.name ?? 'alternative'}
        priority
        width={500}
        height={500}
        className="object-contain w-full h-[215px]"
      />

      <ProductName size="lg" dialogVariant>
        {productData?.name}
      </ProductName>

      <ProductDataContextProvider
        productId={productData.id}
        productSku={productData.sku}
        outOfStock={productData.stock_status === ProductStockStatus.OutOfStock}
        configurableVariants={[]}
        giftCardAmounts={productData.giftcard_amounts}
      >
        <GiftVariants
          productName={productData?.name ?? ''}
          sku={productData?.sku ?? ''}
          giftCardAmounts={
            (productData?.giftcard_amounts as GiftCardProductAmountFragment[]) ??
            []
          }
          isPopupVariant
          onClose={onClose}
        />
      </ProductDataContextProvider>
    </div>
  )
}
