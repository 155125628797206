'use client'

const RECENTLY_VIEWED_PRODUCTS_STORAGE_ID = 'gb_recently_viewed_product'
export const SKU_RECENTLY_VIEWED_PRODUCT = 'sku_recently_viewed_product'

interface ProcessRecentlyViewedProductsProps {
  productId: number | null | undefined
  productSku: string | null | undefined
  outOfStock?: boolean
}

export const processRecentlyViewedProducts = ({
  productId,
  productSku,
  outOfStock = true,
}: ProcessRecentlyViewedProductsProps) => {
  if (productId) {
    const productIds = JSON.parse(
      localStorage.getItem(RECENTLY_VIEWED_PRODUCTS_STORAGE_ID) ?? '[]',
    ) as number[]

    const filteredIds = productIds.filter((id) => id !== productId)
    const updatedIds = [productId, ...filteredIds].slice(0, 5)

    localStorage.setItem(
      RECENTLY_VIEWED_PRODUCTS_STORAGE_ID,
      JSON.stringify(updatedIds),
    )
  }

  if (outOfStock) {
    return
  }

  if (productSku) {
    const productSkus = JSON.parse(
      localStorage.getItem(SKU_RECENTLY_VIEWED_PRODUCT) ?? '[]',
    ) as string[]
    if (productSku) {
      const filteredSkus = productSkus.filter((sku) => sku !== productSku)
      const updatedSkus = [productSku, ...filteredSkus].slice(0, 4)

      localStorage.setItem(
        SKU_RECENTLY_VIEWED_PRODUCT,
        JSON.stringify(updatedSkus),
      )
    }
  }
}
